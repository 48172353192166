/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Box } from '@bottlebooks/gatsby-theme-base';
import useSnipcart from '../../../../../useSnipcart';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ProductSnipcartButton from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductActions/ProductSnipcartButton';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
// import { useLocation } from '@reach/router';

export default function CartButton({ product, ...rest }) {
  const { isItemInCart, canAddMoreProducts } = useSnipcart();
  const link = useLink();
  // const { origin } = useLocation();
  const productLink = link.product({
    productId: product.productId,
    exhibitorId: product.exhibitorId,
  });
  if (isItemInCart(product))
    return (
      <ProductSnipcartButton
        product={product}
        variant="outline"
        disabled
        data-item-price={0}
        data-item-url={productLink}
        {...rest}
      >
        <Trans>Already in cart</Trans>
      </ProductSnipcartButton>
    );

  if (!canAddMoreProducts)
    return (
      <ProductSnipcartButton
        product={product}
        variant="outline"
        disabled
        data-item-price={0}
        data-item-url={productLink}
        {...rest}
      >
        <Trans>Cart is full</Trans>
      </ProductSnipcartButton>
    );

  return (
    <Box sx={{ display: 'inline' }}>
      <ProductSnipcartButton
        product={product}
        data-item-price={0}
        data-item-url={productLink}
        {...rest}
      >
        <Trans>Request sample</Trans>
      </ProductSnipcartButton>
    </Box>
  );
}

export const fragment = graphql`
  fragment bb_ProductRequestSampleWithSnipcartButton on Bottlebooks_Product {
    ...bb_ProductSnipcartButton
  }
`;

CartButton.fragment = gql`
  fragment ProductRequestSampleWithSnipcartButton on Product {
    ...ProductSnipcartButton
  }
  ${ProductSnipcartButton.fragment}
`;
