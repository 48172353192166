/** @jsx jsx */
import { gql } from '@apollo/client';
import Flag from '@bottlebooks/gatsby-theme-event/src/components/Flag/Flag';
import ProductRegionBase from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductRegion';
import { graphql } from 'gatsby';
import uniq from 'lodash/uniq';
import { jsx } from 'theme-ui';

export default function ProductRegion({ product, ...rest }) {
  if (!product) return null;
  return (
    <ProductRegionBase {...rest}>
      <Flag alt="" country={product.countryCode} sx={{ marginRight: 1 }} />
      {uniq([product.countryName, product.denomination])
        .filter(Boolean)
        .join(', ')}
    </ProductRegionBase>
  );
}

export const fragment = graphql`
  fragment custom_ProductRegion on Bottlebooks_Product {
    countryName: country(format: LOCALIZED)
    countryCode: country(format: RAW)
    denomination
  }
`;

export const ProductRegionFragment = gql`
  fragment ProductRegion on Product {
    countryName: country(format: LOCALIZED)
    countryCode: country(format: RAW)
    denomination
  }
`;
